import React from "react";
import styles from "./styles.module.css";
import Button from "../../../../components/Button";
import ESP from "../../../../../../assets/img/ESP.gif";

export default function EasyStoreMakerPro() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>EasySiteWizard Pro : Glissé. Déposé. Terminé.</h1>
        <p>
          EasySiteWizard Pro, qui présente une fonctionnalité « glisser-déposer
          » dynamique, vous offre des modèles de sites Web de haute qualité
          prêts à l'emploi. Cet outil convivial de construction de site Web vous
          permet de créer par vous-même un site d'allure professionnelle en
          quelques minutes.
        </p>
        <p>
          Il suffit de choisir l'un de nos modèles de conception professionnelle
          et d'y aller!
        </p>
        <img src={ESP} alt="Assistant de création de site Web Pro"></img>
        <ul>
          <li>
            Avec nos modèles de conception professionnelle, on croira votre site
            créé par un designer de haut niveau.
          </li>
          <li>
            L'interface de conception tel écran-tel écrit (Â« What you see is
            what you get ») ne requiert aucune expérience du HTML, aussi vous
            pouvez créer un site unique sans aucune aide.
          </li>
          <li>
            Vous êtes à la recherche d'images qui compléteront à merveille vos
            pages? Il suffit d'un clic pour effectuer une recherche rapide parmi
            les milliers d'images libres de droits de notre iconothèque
            intégrée.
          </li>
          <li>
            Ajoutez facilement votre propre texte, des images, des en-têtes ou
            des liens.
          </li>
        </ul>
        <Button text={"Comparer les forfaits"} />
      </div>
      <div className={styles.row}>
        <h2>Pourquoi choisir EasySiteWizard Pro?</h2>
        <div className={styles.column}>
          <h3>Caractéristiques</h3>
          <ul>
            <li>Fonction « glisser-déposer »</li>
            <li>Gestion des sous-menus</li>
            <li>Fonction « sauvegarder et publier »</li>
            <li>Modèles malléables</li>
            <li>Vérificateur d'épellation</li>
            <li>Technologie AJAX</li>
            <li>Interface moderne</li>
            <li>Iconothèque</li>
            <li>Éditeur d'images</li>
          </ul>
        </div>
        <div className={styles.column}>
          <h3>Avantages</h3>
          <ul>
            <li>
              Redimensionnez boîtes de texte et images et déplacez-les n'importe
              où dans le modèle.
            </li>
            <li>
              Une navigation optimisée permet de gérer un contenu de site plus
              important ainsi qu'un nombre illimité de pages Web.
            </li>
            <li>
              Sauvegardez, prévisualisez et publiez le fruit de votre travail
              directement à partir de la barre d'outils.
            </li>
            <li>
              Les modèles sans cadre rendent flexible la conception graphique en
              permettant le redimensionnement et la personnalisation des images.
            </li>
            <li>
              Le vérificateur d'épellation fonctionne avec toutes les langues
              prises en charge.
            </li>
            <li>Interactions, vitesse et convivialité maximisées.</li>
            <li>
              Avec sa disposition simple, l'interface d'édition est conviviale,
              nette et fonctionnelle.
            </li>
            <li>
              L'iconothèque contient des dizaines de milliers de superbes images
              pouvant être librement insérées dans les modèles EasySiteWizard
              Pro.
            </li>
            <li>
              Personnalisez les images que vous voulez intégrer à votre site
              Web.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

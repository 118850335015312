import React from "react";
import Layout from "../../../../../other/layout";
import EasySiteWizardPro from "../../../../../components/AC-StaticPages/Developpez/ServicesWeb/ConceptionDeSitesWeb/EasySiteWizardPro/EasySiteWizardPro";
import SEO from "../../../../../other/seo";

export default function easySiteWizardPro() {
  return (
    <Layout>
      <SEO title="EasySiteWizard Pro : Glissé. Déposé. Terminé" />
      <EasySiteWizardPro />
    </Layout>
  );
}
